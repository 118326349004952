<template>
  <div class="">
    <Card class="p-m-2">
      <template #title> Onay Bekleyen Talepler </template>
      <template #content>
        <TalepDataTable v-if="TalepDataTable_render" :columns="columns" :data="TalepListesi"
          v-on:set-child-data="getDataChild" />
      </template>
    </Card>
    <!-- Talep Edit -->
    <Dialog v-model:visible="TalepModal" :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '65vw' }"
      :modal="true">
      <template #header>
        <h4>Talep Detay</h4>
      </template>

      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-4">
          <span for="spanBarkod">Barkod</span>
          <InputText id="Barkod" type="text" v-model="v$.Barkod.$model" aria-labelledby="spanBarkod" disabled />
        </div>

        <div class="p-field p-col-4">
          <span for="spanMusteriAd">Müşteri Adı</span>
          <InputText id="MusteriAd" v-model="v$.MusteriAd.$model" aria-labelledby="spanMusteriAd"
            :class="{ 'p-invalid': v$.MusteriAd.$invalid && submitted }" />
          <small v-if="(v$.MusteriAd.$invalid && submitted) ||
          v$.MusteriAd.$pending.$response
          " class="p-error">
            {{ v$.MusteriAd.required.$message.replace("Value", "Name") }}
          </small>
        </div>
        <div class="p-field p-col-4">
          <span for="spanOlusturmaTarih">Oluşturma Tarih</span>
          <Calendar id="OlusturmaTarih" disabled v-model="v$.OlusturmaTarih.$model"
            aria-labelledby="spanOlusturmaTarih" />
        </div>
        <div class="p-field p-col-4">
          <span for="spanTelefonNo">Telefon No</span>
          <InputMask id="TelefonNo" v-model="v$.TelefonNo.$model" mask="(999) 999-9999" placeholder="(___) ___-____"
            aria-labelledby="spanTelefonNo" :class="{ 'p-invalid': v$.TelefonNo.$invalid && submitted }" />
          <small v-if="(v$.TelefonNo.$invalid && submitted) ||
          v$.TelefonNo.$pending.$response
          " class="p-error">
            {{ v$.TelefonNo.required.$message.replace("Value", "Name") }}
          </small>
        </div>
        <div class="p-field p-col-4">
          <span for="spanEMail">Müşteri EMail</span>
          <InputText id="EMail" v-model="v$.EMail.$model" aria-labelledby="spanEMail"
            :class="{ 'p-invalid': v$.EMail.$invalid && submitted }" />
          <small v-if="(v$.EMail.$invalid && submitted) ||
          v$.EMail.$pending.$response
          " class="p-error">
            {{ v$.EMail.required.$message.replace("Value", "Name") }}
          </small>
        </div>
        <div class="p-field p-col-4"></div>

        <div class="p-field p-col-4">
          <span for="spanSehirAd">Şehir</span>
          <Dropdown filter id="SehirAd" v-model="v$.SehirKod.$model" :options="Sehir" optionLabel="SehirAdi"
            optionValue="SehirKod" placeholder="Seçiniz..." @change="CityChange"
            :class="{ 'p-invalid': v$.SehirKod.$invalid && submitted }" />
          <small v-if="(v$.SehirKod.$invalid && submitted) ||
          v$.SehirKod.$pending.$response
          " class="p-error">
            {{ v$.SehirKod.required.$message.replace("Value", "Name") }}
          </small>
        </div>
        <div class="p-field p-col-4">
          <span for="spanIlce">İlçe</span>
          <Dropdown filter id="IlceAd" v-model="v$.IlceKod.$model" :options="temp_ilce" optionLabel="IlceAdi"
            optionValue="IlceKod" placeholder="Seçiniz..." :disabled="ilceDisabled"
            :class="{ 'p-invalid': v$.IlceKod.$invalid && submitted }" />
          <small v-if="(v$.IlceKod.$invalid && submitted) ||
          v$.IlceKod.$pending.$response
          " class="p-error">
            {{ v$.IlceKod.required.$message.replace("Value", "Name") }}
          </small>
        </div>
        <div class="p-field p-col-8">
          <span for="spanMusteriAdres">Muşteri Adres</span>
          <Textarea id="MusteriAdres" v-model="v$.MusteriAdres.$model" :autoResize="false" rows="2"
            aria-labelledby="spanMusteriAdres" :class="{ 'p-invalid': v$.MusteriAdres.$invalid && submitted }" />
          <small v-if="(v$.MusteriAdres.$invalid && submitted) ||
          v$.MusteriAdres.$pending.$response
          " class="p-error">
            {{ v$.MusteriAdres.required.$message.replace("Value", "Name") }}
          </small>
        </div>
        <div class="p-field p-col-4"></div>
        <div class="p-field p-col-4">
          <span for="spanUrunTur">Ürün Türü</span>
          <Dropdown filter id="UrunTur" v-model="v$.UrunTurKod.$model" :options="UrunTur" optionLabel="TurAd"
            optionValue="TurKod" placeholder="Seçiniz..."
            :class="{ 'p-invalid': v$.UrunTurKod.$invalid && submitted }" />
          <small v-if="(v$.UrunTurKod.$invalid && submitted) ||
          v$.UrunTurKod.$pending.$response
          " class="p-error">
            {{ v$.UrunTurKod.required.$message.replace("Value", "Name") }}
          </small>
        </div>
        <div class="p-field p-col-4">
          <span for="spanUrunMarkaAd">Ürün Marka</span>
          <Dropdown filter id="UrunMarka" v-model="v$.UrunMarkaKod.$model" :options="UrunMarka" optionLabel="MarkaAd"
            optionValue="MarkaKod" placeholder="Seçiniz..."
            :class="{ 'p-invalid': v$.UrunMarkaKod.$invalid && submitted }" />
          <small v-if="(v$.UrunMarkaKod.$invalid && submitted) ||
          v$.UrunMarkaKod.$pending.$response
          " class="p-error">
            {{ v$.UrunMarkaKod.required.$message.replace("Value", "Name") }}
          </small>
        </div>
        <div class="p-field p-col-4">
          <span for="spanUrunModel">Ürün Model</span>

          <Dropdown filter id="UrunModel" v-model="v$.UrunModelKod.$model" :options="UrunModel" optionLabel="ModelAd"
            optionValue="ModelKod" placeholder="Seçiniz..."
            :class="{ 'p-invalid': v$.UrunModelKod.$invalid && submitted }" />
          <small v-if="(v$.UrunModelKod.$invalid && submitted) ||
          v$.UrunModelKod.$pending.$response
          " class="p-error">
            {{ v$.UrunModelKod.required.$message.replace("Value", "Name") }}
          </small>
        </div>
        <div class="p-field p-col-8">
          <span for="spanArizaAciklama">Arıza Açıklama</span>
          <Textarea id="ArizaAciklama" v-model="v$.ArizaAciklama.$model" :autoResize="false" rows="2"
            aria-labelledby="spanArizaAciklama" :class="{ 'p-invalid': v$.ArizaAciklama.$invalid && submitted }" />
          <small v-if="(v$.ArizaAciklama.$invalid && submitted) ||
          v$.ArizaAciklama.$pending.$response
          " class="p-error">
            {{ v$.ArizaAciklama.required.$message.replace("Value", "Name") }}
          </small>
        </div>
        <div class="p-field p-col-4"></div>
        <div class="p-field p-col-4">
          <span for="spanTeknikServisAd">Teknik Servis</span>
          <InputText disabled type="text" id="TeknikServisAd" v-model="v$.TeknikServisAd.$model"
            aria-labelledby="spanTeknikServis" :class="{ 'p-invalid': v$.TeknikServisAd.$invalid && submitted }" />
          <small v-if="(v$.TeknikServisAd.$invalid && submitted) ||
          v$.TeknikServisAd.$pending.$response
          " class="p-error">
            {{ v$.TeknikServisAd.required.$message.replace("Value", "Name") }}
          </small>
        </div>
        <div class="p-field p-col-4">
          <Button class="formButon" label="Değiştir" severity="error" outlined @click="this._TalepServisModal()" />
        </div>
        <div class="p-field p-col-4"></div>
        <div class="p-field p-col-4">
          <span for="spanTalepDurum">Talep Durumu</span>
          <InputText disabled type="text" id="TalepDurumAd" v-model="v$.TalepDurumAd.$model"
            aria-labelledby="spanTalepDurum" />
        </div>
        <div class="p-field p-col-4">
          <Toast />
          <ConfirmPopup></ConfirmPopup>
          <span class="p-buttonset formButon">
            <Button :disabled="OnayButtonDisabled" @click="OnayClick($event)" label="Onay" icon="pi pi-check" />
            <Button :disabled="OnayButtonDisabled" @click="RedClick($event)" label="Red" icon="pi pi-times" />
          </span>
        </div>
      </div>
      <template #footer>
        <Button label="Tamam" icon="pi pi-check" class="p-button-sm p-button-success"
          @click="this.TalepEdit(!v$.$invalid)" autofocus />
        <Button label="Kapat" icon="pi pi-times" class="p-button-sm p-button-danger" @click="this._TalepModal()" />
      </template>
    </Dialog>
    <!-- Talep Servis Değiştir -->
    <Dialog v-model:visible="TalepServisDegistir" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '65vw' }" :modal="true">
      <template #header>
        <h4>Servis Değiştir</h4>
      </template>
      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-4">
          <span for="spanSehirAd">Şehir</span>
          <Dropdown filter id="SehirAd" v-model="v$.SehirKod.$model" :options="Sehir" optionLabel="SehirAdi"
            optionValue="SehirKod" placeholder="Seçiniz..." @change="CityChange"
            :class="{ 'p-invalid': v$.SehirKod.$invalid && submitted }" />
          <small v-if="(v$.SehirKod.$invalid && submitted) ||
          v$.SehirKod.$pending.$response
          " class="p-error">
            {{ v$.SehirKod.required.$message.replace("Value", "Name") }}
          </small>
        </div>
        <div class="p-field p-col-4">
          <span for="spanIlce">İlçe</span>
          <Dropdown filter id="IlceAd" v-model="v$.IlceKod.$model" :options="this.temp_ilce" optionLabel="IlceAdi"
            optionValue="IlceKod" placeholder="Seçiniz..." :disabled="ilceDisabled"
            :class="{ 'p-invalid': v$.IlceKod.$invalid && submitted }" @change="IlceChange" />
          <small v-if="(v$.IlceKod.$invalid && submitted) ||
          v$.IlceKod.$pending.$response
          " class="p-error">
            {{ v$.IlceKod.required.$message.replace("Value", "Name") }}
          </small>
        </div>
        <div class="p-field p-col-4">
          <span for="spanTeknikServis">Teknik Servis</span>
          <Dropdown filter id="TeknikServis" :disabled="TeknikServisDisabled" v-model="v$.TeknikServisKod.$model"
            :options="temp_TeknikServis" optionLabel="TeknikServisAd" optionValue="TeknikServisKod"
            placeholder="Seçiniz..." :class="{ 'p-invalid': v$.TeknikServisKod.$invalid && submitted }" />
          <small v-if="(v$.TeknikServisKod.$invalid && submitted) ||
          v$.TeknikServisKod.$pending.$response
          " class="p-error">
            {{ v$.TeknikServisKod.required.$message.replace("Value", "Name") }}
          </small>
        </div>
      </div>
      <template #footer>
        <Button label="Tamam" icon="pi pi-check" class="p-button-sm p-button-success" autofocus
          @click="this.TalepServisEdit(!v$.$invalid)" />
        <Button label="Kapat" icon="pi pi-times" class="p-button-sm p-button-danger"
          @click="this._TalepServisModal()" />
      </template>
    </Dialog>

    <!-- Red Nedeni -->
    <Dialog v-model:visible="RedModal" :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '25vw' }"
      :modal="true" header="Red Nedeni">
      <!-- <template #header>
        <h4>Talep Red</h4>
      </template> -->

      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-12">
          <Textarea id="RedNedeni" v-model="v$.RedNedeni.$model" :autoResize="true" rows="2"
            aria-labelledby="spanRedNedeni" :class="{ 'p-invalid': v$.RedNedeni.$invalid && submitted }" />
          <small v-if="(v$.RedNedeni.$invalid && submitted) ||
          v$.RedNedeni.$pending.$response
          " class="p-error">
            {{ v$.RedNedeni.required.$message.replace("Value", "Name") }}
          </small>
        </div>
      </div>
      <template #footer>
        <Button label="Tamam" icon="pi pi-check" class="p-button-sm p-button-success" autofocus
          @click="this.Red(!v$.$invalid)" />
        <Button label="Kapat" icon="pi pi-times" class="p-button-sm p-button-danger" @click="this.RedModal = false" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import TalepDataTable from "../DataTable/TalepDataTable.vue";
import GlobalServis from "../../../services/GlobalServis";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      TalepDataTable_render: true,
      TalepModal: false,
      OnayButtonDisabled: true,
      TalepServisDegistir: false,
      TalepListesi: [],
      columns: [
        { field: "Barkod", header: "Barkod" },
        { field: "MusteriAd", header: "Musteri Adı" },
        { field: "Tarih", header: "Tarih" },
        { field: "Sehir", header: "Sehir" },
        { field: "UrunTur", header: "Urun Tur" },
        // { field: "Marka", header: "Marka" },
        // { field: "Model", header: "Model" },
        { field: "TeknikServisAdi", header: "Teknik Servis Adı" },
      ],
      SehirIlce: [],
      Sehir: [],
      Ilce: [],
      temp_ilce: [],
      ilceDisabled: true,
      Talep_rowid: null,

      UrunTur: [],
      UrunMarka: [],
      UrunModel: [],

      Barkod: null,
      MusteriAd: null,
      OlusturmaTarih: null,
      TelefonNo: null,
      EMail: null,
      SehirKod: null,
      IlceKod: null,
      MusteriAdres: null,
      UrunTurKod: null,
      UrunMarkaKod: null,
      UrunModelKod: null,
      TeknikServisAd: null,
      ArizaAciklama: null,
      TalepDurumAd: null,

      TeknikServisDisabled: true,
      TeknikServis: [],
      temp_TeknikServis: [],
      TeknikServisKod: null,

      RedNedeni: null,
      RedModal: false,
    };
  },
  methods: {
    getDataChild(getdata_child) {
      this.TalepModal = !this.TalepModal;
      this.Talep_rowid = getdata_child.data.Talep_rowid;
      let data = {
        Talep_rowid: getdata_child.data.Talep_rowid,
      };
      GlobalServis.GlobalServis("POST", "GetTalepInfo", data).then((res) => {
        if (res.status == 200) {
          this.v$.Barkod.$model = res.data.Talep[0].Barkod;
          this.v$.MusteriAd.$model = res.data.Talep[0].MusteriAd;
          this.v$.OlusturmaTarih.$model = res.data.Talep[0].OlusturmaTarih;
          this.v$.TelefonNo.$model = res.data.Talep[0].TelefonNo;
          
          this.v$.EMail.$model = res.data.Talep[0].EMail;

          this.v$.SehirKod.$model = res.data.Talep[0].pSehir;
          this.v$.IlceKod.$model = res.data.Talep[0].pIlce;
          this.v$.MusteriAdres.$model = res.data.Talep[0].MusteriAdres;
          this.v$.UrunTurKod.$model = res.data.Talep[0].TurKod;
          this.v$.UrunMarkaKod.$model = res.data.Talep[0].MarkaKod;
          this.v$.UrunModelKod.$model = res.data.Talep[0].ModelKod;
          this.v$.TeknikServisAd.$model = res.data.Talep[0].TeknikServisAd;
          this.v$.TeknikServisKod.$model = res.data.Talep[0].TeknikServisKod;
          this.v$.ArizaAciklama.$model = res.data.Talep[0].ArizaAciklama;
          this.v$.TalepDurumAd.$model = res.data.Talep[0].TalepDurumAd;
          this.OnayButtonDisabled = res.data.TalepOnayliMi[0].TalepOnayliMi;

          // this.ilceDisabled = false;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    _TalepModal() {
      this.TalepModal = !this.TalepModal;
      if (!this.TalepModal) {
        this.ResetForm();
        this.Refresh();
      }
    },
    _TalepServisModal() {
      this.TalepServisDegistir = !this.TalepServisDegistir;
      if (!this.TalepServisDegistir) {
        //  this.resetForm();
      }
    },
    _RedModal() {
      this.RedModal = !this.RedModal;
      if (!this.RedModal) {
        //  this.resetForm();
      }
    },
    OnayClick(event) {
      this.$confirm.require({
        target: event.currentTarget,
        message: "Talebi onaylıyorsunuz..",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          let data = {
            Talep_rowid: this.Talep_rowid,
            OnayDurum: true,
          };
          GlobalServis.GlobalServis("POST", "UpdateTalepOnay", data).then(
            (res) => {
              if (res.status == 200 && res.data == "") {
                this.$toast.add({
                  severity: "success",
                  summary: "Başarılı",
                  detail: "Talep Onaylanmıştır",
                  life: 3000,
                });
                this._TalepModal();
              } else if (res.status == 200 && res.data != "") {
                this.$toast.add({
                  severity: "warn",
                  summary: "Uyarı",
                  detail: res.data,
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Hata oldu",
                  life: 3000,
                });
              }
            }
          );
        },
        reject: () => {
          this.$toast.add({
            severity: "info",
            summary: "İptal",
            detail: "İşlem İptal Edildi",
            life: 3000,
          });
        },
      });
    },
    RedClick(event) {
      this.$confirm.require({
        target: event.currentTarget,
        message: "Talebi reddediyorsunuz..",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this._RedModal();
        },
        reject: () => {
          this.$toast.add({
            severity: "info",
            summary: "İptal",
            detail: "İşlem İptal Edildi",
            life: 3000,
          });
        },
      });
    },
    Red(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        let data = {
          Talep_rowid: this.Talep_rowid,
          OnayDurum: false,
          RedNedeni: this.RedNedeni,
        };
        GlobalServis.GlobalServis("POST", "UpdateTalepOnay", data).then(
          (res) => {
            if (res.status == 200 && res.data == "") {
              this.$toast.add({
                severity: "success",
                summary: "Başarılı",
                detail: "Talep Reddedildi",
                life: 3000,
              });
              this._TalepModal();
              this._RedModal();
            } else if (res.status == 200 && res.data != "") {
              this.$toast.add({
                severity: "warn",
                summary: "Uyarı",
                detail: res.data,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Hata",
                detail: "Hata oldu",
                life: 3000,
              });
            }
          }
        );
      }
    },
    CityChange(e) {
      this.temp_ilce = this.Ilce.filter(function (i) {
        if (e.value == 0) {
          return i.pSehir == e.value.substring(1);
        } else {
          return i.pSehir == e.value;
        }
      });
      this.ilceDisabled = false;
    },
    IlceChange(e) {
      this.temp_TeknikServis = this.TeknikServis.filter(function (i) {
        if (e.value == 0) {
          return i.IlceKod == e.value.substring(1);
        } else {
          return i.IlceKod == e.value;
        }
      });
      this.TeknikServisDisabled = false;
      if (this.temp_TeknikServis.length === 0) {
        this.$toast.add({
          severity: "info",
          summary: "Uyarı",
          detail: "Bu bölgede servis mevcut değildir",
          life: 3000,
        });
        this.TeknikServisDisabled = true;
      }
    },
    ResetForm() {
      this.Barkod = null;
      this.MusteriAd = null;
      this.OlusturmaTarih = null;
      this.TelefonNo = null;
      this.SehirKod = null;
      this.IlceKod = null;
      this.MusteriAdres = null;
      this.UrunTurKod = null;
      this.UrunMarkaKod = null;
      this.UrunModelKod = null;
      this.TeknikServisAd = null;
      this.ArizaAciklama = null;
      this.TalepDurumAd = null;
      this.TeknikServisKod = null;
      this.OnayButtonDisabled = false;
      this.RedNedeni = null;
    },
    TalepEdit(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        let data = {
          Talep_rowid: this.Talep_rowid,
          MusteriAd: this.MusteriAd,
          TelefonNo: this.TelefonNo,
          EMail : this.EMail,
          SehirKod: this.SehirKod,
          IlceKod: this.IlceKod,
          MusteriAdres: this.MusteriAdres,
          UrunTurKod: this.UrunTurKod,
          UrunMarkaKod: this.UrunMarkaKod,
          UrunModelKod: this.UrunModelKod,
          ArizaAciklama: this.ArizaAciklama,
        };
        GlobalServis.GlobalServis("POST", "UpdateTalep", data).then((res) => {
          if (res.status == 200 && res.data == "") {
            this.$toast.add({
              severity: "success",
              summary: "Başarılı",
              detail: "Talep düzenlemiştir",
              life: 3000,
            });
            this.Refresh();
            this._TalepModal();
          } else if (res.status == 200 && res.data != "") {
            this.$toast.add({
              severity: "warn",
              summary: "Uyarı",
              detail: res.data,
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Hata oldu",
              life: 3000,
            });
          }
        });
      }
    },
    TalepServisEdit(isFormValid) {
      this.submitted = true;
      // if (isFormValid) {
        let data = {
          Talep_rowid: this.Talep_rowid,
          SehirKod: this.SehirKod,
          IlceKod: this.IlceKod,
          TeknikServisKod: this.TeknikServisKod,
        };
        GlobalServis.GlobalServis("POST", "UpdateTalepServis", data).then(
          (res) => {
            if (res.status == 200 && res.data == "") {
              this.$toast.add({
                severity: "success",
                summary: "Başarılı",
                detail: "Talep düzenlemiştir",
                life: 3000,
              });
              this.temp_TeknikServis.forEach((value) => {
                if (value.TeknikServisKod === this.TeknikServisKod) {
                  this.TeknikServisAd = value.TeknikServisAd;
                }
              });
              this._TalepServisModal();
            } else if (res.status == 200 && res.data != "") {
              this.$toast.add({
                severity: "warn",
                summary: "Uyarı",
                detail: res.data,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Hata",
                detail: "Hata oldu",
                life: 3000,
              });
            }
          }
        );
      // }
    },
    Refresh() {
      this.TalepDataTable_render = false;
      this.$nextTick(() => {
        this.InitServis()
        this.TalepDataTable_render = true;
      });
    },
    InitServis() {
      GlobalServis.GlobalServis("GET", "GetAlinanTalepTablosu", "").then((res) => {
        if (res.status == 200) {
          this.TalepListesi = res.data;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
      GlobalServis.GlobalServis("GET", "GetSehirIlce", "").then((res) => {
        if (res.status == 200) {
          this.Sehir = res.data.Sehir;
          console.log(this.Sehir);
          this.Ilce = res.data.Ilce;
          this.temp_ilce = res.data.Ilce;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
      GlobalServis.GlobalServis("GET", "GetUrunTurMarkaModel", "").then((res) => {
        if (res.status == 200) {
          this.UrunTur = res.data.Tur;
          this.UrunMarka = res.data.Marka;
          this.UrunModel = res.data.Model;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
      GlobalServis.GlobalServis("GET", "GetTeknikServis", "").then((res) => {
        if (res.status == 200) {
          this.TeknikServis = res.data.TeknikServis;
          this.temp_TeknikServis = res.data.TeknikServis;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });

    }
  },
  mounted() {
    this.InitServis();
  },
  components: {
    TalepDataTable,
  },
  validations() {
    return {
      Barkod: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      MusteriAd: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      OlusturmaTarih: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      TelefonNo: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      EMail: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      SehirKod: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      IlceKod: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      MusteriAdres: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      UrunTurKod: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      UrunMarkaKod: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      UrunModelKod: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      ArizaAciklama: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      TeknikServisAd: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      TalepDurumAd: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      TeknikServisKod: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      RedNedeni: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
    };
  },
};
</script>

<style scoped>
.formButon {
  margin-top: 20px;
}
</style>
